.primary-btn {
  background-color: $primary !important;
  padding: 1rem;
  color: white;
  margin-top: 2em;
  @media (max-width: $small) {
    width: 230px;
  }
}

.secondary-btn {
  background-color: $primary !important;
}

.btn-outline {
  color: white;

  &:hover {
    background-color: transparent !important;
    opacity: 0.8;
  }
}

.pulse {
  cursor: pointer;
  display: inline-block;
  margin-bottom: 15px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  padding: 20px;
  text-align: center;
  width: 100%;
  max-width: 350px;
  border-radius: 10px;
  background: $primary;
  border: 2px solid $primary;
  animation: pulse 2s infinite;
  transition: all 0.3s ease-in-out;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(45, 120, 247, 0.4);
  }
  100% {
    box-shadow: 0 0 0 25px rgba(45, 120, 247, 0);
  }
}
